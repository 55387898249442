import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import FeaturedImage from "../components/common/FeaturedImage"
import SEO from "../components/seo"
export default function Kontorsplats() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 32 }) {
        id
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.6)
              }
            }
          }
        }
      }
    }
  `)
  const { title, content, featuredImage } = data.wpPage
  return (
    <Layout>
      <SEO title="Hyr kontorsplats" />
      <div className="grid gap-4 md:gap-8">
        <div>
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div>
          <FeaturedImage image={featuredImage} />
        </div>
      </div>
    </Layout>
  )
}
